import React from 'react'

const App = () => {
    return (
        <>
            {/* header */}
            <div className='w-full h-screen flex bg-blue-100'>
                <header
                    class="pt-10 w-full bg-[url('')] bg-blue-100 bg-cover bg-center flex justify-center items-center">
                    <div class="flex flex-col justify-center items-center">
                        <div className='flex flex-col items-center gap-10'>
                            <img class="w-36 rounded-full ring-4 ring-primary" src={require('./assets/pp.png')} alt="avatar" />
                            <img class="w-80 mb-20" src={require('./assets/Logo.png')} alt="avatar" />
                        </div>
                        <h1 class="font-arya text-center text-3xl text-white font-bold drop-shadow-lg">
                            <span class="text-primary">समाज रुपान्तरणको निम्ति स्वतन्त्र युवा अभियन्ता</span>
                        </h1>
                        <h2 class="font-arya text-center text-xl text-red-800 font-bold drop-shadow-lg mt-2">
                            झापा क्षेत्र नम्बर ३, अत्यधिक मतले बिजयी गराऔ
                        </h2>
                        <a class="font-arya mt-8 px-12 py-3 bg-primary hover:from-blue-800 hover:to-blue-900 text-xl text-white/90 font-semibold drop-shadow-lg rounded-full hover:underline mb-20"
                            href="tel:+977 9813897310">सम्पर्क</a>
                    </div>
                </header>
            </div>
            <footer className='flex flex-col items-center justify-center p-2 border-t border-black/10 w-full bg-blue-100'>
                <h2 className='text-sm'>
                    Yogendra Khanal © {
                        new Date().getFullYear()
                    }. All rights reserved.
                </h2>
                <h3 className='text-xs italic'>
                    This site is officially a part of Yogesh Khanal's campaign to Nepal's General Election 2022.
                </h3>
            </footer>
        </>
    )
}

export default App